import React from 'react';
import Header from '../SubMain/Header';
import Hero from '../SubMain/Hero';
import Navbar from '../SubMain/Navbar';
import Main from '../SubMain/Main';
import Footer from '../SubMain/Footer'

function Home() {

  return (
      <>
        <Header/>
        <Navbar/>
        <Hero/>
        <Main/>
        <Footer />
      </>
  )

}

export default Home;
